body {
	font-family: "Courier New", Courier, monospace;
	background: #edf5e1;
}

.App {
	text-align: left;
}
/* 
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
	color: #09d3ac;
}

.embeded-sc {
	font-size: 10px;
	color: #cccccc;
	line-break: anywhere;
	word-break: normal;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
		Garuda, Verdana, Tahoma, sans-serif;
	font-weight: 100;
}

.embeded-audius {
	font-size: 10px;
	/* color: #cccccc; */
	/* line-break: anywhere; */
	/* word-break: normal; */
	/* overflow: hidden; */
	/* white-space: nowrap; */
	/* text-overflow: ellipsis; */
	/* font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Garuda, Verdana, Tahoma, sans-serif; */
	/* font-weight: 100; */
}

.embededStyleLink {
	color: #cccccc;
	text-decoration: none;
}

.resume-frame {
	display: block;
	border-style: none;
	margin: auto;
	width: 836px;
	height: 1035px;
	text-align: center;
}
